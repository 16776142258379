module.exports = {
  defaultTitle: 'Justin Fortunato | Full Stack Web Developer',
  logo: 'https://jfortunato.github.io/favicon/favicon-512.png',
  author: 'Justin Fortunato',
  url: 'https://jfortunato.com',
  legalName: 'Justin Fortunato',
  defaultDescription: 'I’m Justin and I’m a Full Stack Web Developer!',
  socialLinks: {
    github: 'https://github.com/jfortunato',
  },
  googleAnalyticsID: 'UA-22673798-10',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  address: {
    city: 'City',
    region: 'Region',
    country: 'Country',
    zipCode: 'ZipCode',
  },
  contact: {
    email: 'email',
    phone: 'phone number',
  },
  foundingDate: '2018',
  recaptcha_key: '6LdpptQUAAAAAOS3AkgKEtM7RZUjTLTH8mfEmofr',
};
